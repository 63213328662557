<template>
  <div class="figure__wrapper" :class="{ 'figure__wrapper-details': isDetails }">
    <svg
      :width="defaultSvgParams.width * figureSvgScale"
      :height="defaultSvgParams.height * figureSvgScale"
      :viewBox="`0 0 ${defaultSvgParams.width * figureSvgScale}
         ${defaultSvgParams.height * figureSvgScale}`"
      ref="figure"
      :style="{ transform: `matrix(${zoom},0,0,${zoom},0,0)` }"
    >
      <!-- <defs v-if="!isNav">
        <filter x="-25%" y="-30%" width="1.5" height="1.6" id="text-bg">
          <feFlood flood-color="#fff" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
            <feMorphology in="SourceAlpha" operator="dilate" radius="4" />
          </feMerge>
        </filter>
      </defs>-->
      <ruler
        :x1="0"
        :y1="changeHeightUp"
        :x2="defaultSvgParams.width"
        :y2="changeHeightUp"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeHeightUp + scaledBiasHeight"
        :x2="defaultSvgParams.width"
        :y2="changeHeightUp + scaledBiasHeight"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeHeightDown + scaledBiasHeight"
        :x2="defaultSvgParams.width"
        :y2="changeHeightDown + scaledBiasHeight"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthLeft"
        :y1="0"
        :x2="changeWidthLeft"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthLeft + scaledBiasWidth"
        :y1="0"
        :x2="changeWidthLeft + scaledBiasWidth"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthRight"
        :y1="0"
        :x2="changeWidthRight"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <size-arrow :path="arrowHeight" :is-details="isDetails" color="blue" v-if="!isNav" />
      <size-arrow :path="arrowWidth" :is-details="isDetails" color="green" v-if="!isNav" />
      <size-arrow :path="arrowBiasHeight" :is-details="isDetails" color="violet" v-if="!isNav" />
      <size-arrow :path="arrowBiasWidth" :is-details="isDetails" color="orange" v-if="!isNav" />
      <size
        v-if="!isNav"
        :x="changeWidthRight + 30"
        :y="arrowDirectionY"
        :text="`E = ${slopeParams.height}`"
        :fill="isDetails ? colors.grey : colors.blue"
      />
      <size
        v-if="!isNav"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeHeightDown + scaledBiasHeight + 30"
        :text="`A = ${slopeParams.width}`"
        :fill="isDetails ? colors.grey : colors.green"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="changeHeightUp + scaledBiasHeight / 2"
        :text="`B = ${slopeParams.biasHeight}`"
        :fill="isDetails ? colors.grey : colors.violet"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft + scaledBiasWidth / 2"
        :y="changeHeightUp - 30"
        :text="`C = ${slopeParams.biasWidth}`"
        :fill="isDetails ? colors.grey : colors.orange"
      />
      <defs v-if="isPlate">
        <clipPath :id="`figureMask--sector-${slopeFromState.uid}`">
          <path
            :d="cornerLeftBottomMask"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // corner left bottom mask
        </clipPath>
      </defs>
      <g
        v-if="isDetails"
        :clip-path="`url(#figureMask--sector-${slopeFromState.uid})`"
        name="rowsForTooltip"
      >
        <!--clip-path="url(#figureMask)"-->

        <g v-for="i in rowsCount" :key="i" class="row__overlay">
          <rect
            :x="changeWidthRight - plateWidth * i"
            :y="i % 2 === 0 ? rowStartY - plateHeight / 2 : rowStartY"
            :width="plateWidth - 1"
            :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
            fill="#F6F6F6"
            stroke="#999999"
            stroke-width="1"
            style="shape-rendering: crispEdges"
            @mouseover="showRowInfo(i)"
            @mouseout="hideRowInfo()"
          ></rect>
          <line
            :x1="changeWidthRight - plateWidth * i"
            :y1="
              i % 2 === 0
                ? rowStartY - plateHeight / 2 + plateHeight * n
                : rowStartY + plateHeight * n
            "
            :x2="changeWidthRight - plateWidth * i + plateWidth - 1"
            :y2="
              i % 2 === 0
                ? rowStartY - plateHeight / 2 + plateHeight * n
                : rowStartY + plateHeight * n
            "
            stroke="#999999"
            stroke-width="1"
            style="shape-rendering: crispEdges"
            v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
            :key="n"
          ></line>
          <row-number :opts="getRowNumberOpts(i)" />
        </g>
      </g>
      <path
        :d="cornerLeftBottom"
        class="scheme-circuit"
        fill="none"
        :stroke="figureStrokeColor"
        :stroke-width="strokeWidth"
        style="stroke-linecap: round"
      ></path>
      <size-arrow
        :path="arrowDirection"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
      />
    </svg>
  </div>
</template>

<script>
import RowNumber from '@/components/figure_elements/RowNumber'
import {
  getRowNumberFontSize,
  getRowNumberX,
  colors,
  drawSizeArrowVertical,
  drawSizeArrowHorizontal,
  drawArrowDirection
} from '@/utils/drawing'
import Ruler from '@/components/figure_elements/Ruler'
import SizeArrow from '@/components/figure_elements/SizeArrow'
import Size from '@/components/figure_elements/Size'
import { getMin } from '@/utils'

export default {
  props: {
    isNav: Boolean,
    defaultSvgParams: Object,
    isDetails: Boolean,
    isPlate: Boolean,
    navId: Number,
    zoom: Number,
    rowId: {
      type: Number,
      default: 0
    },
    isForTotalPdf: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Size,
    SizeArrow,
    Ruler,
    RowNumber
  },
  watch: {
    slopeParams: {
      deep: true,
      handler() {
        this.slopeParams.biasWidth > this.slopeParams.width
          ? (this.slopeParams.biasWidth = this.slopeParams.width)
          : (this.slopeParams.width = this.slopeParams.width)
        this.slopeParams.biasWidth >= this.slopeParams.width
          ? (this.slopeParams.biasWidth = this.slopeParams.width)
          : (this.slopeParams.biasWidth = this.slopeParams.biasWidth)
      }
    }
    // width() {
    //   (this.slopeParams.biasWidth > this.slopeParams.width)
    //     ? this.slopeParams.width = this.slopeParams.biasWidth
    //     : this.slopeParams.width = this.slopeParams.width;
    // },
    // biasWidth() {
    //   (this.slopeParams.biasWidth >= this.slopeParams.width)
    //     ? this.slopeParams.width = this.slopeParams.biasWidth
    //     : this.slopeParams.biasWidth = this.slopeParams.biasWidth;
    // },
  },
  methods: {
    getRowNumberOpts(i) {
      return {
        fontSize: getRowNumberFontSize(this.plateWidth),
        x: getRowNumberX(this.changeWidthRight, this.plateWidth, i),
        y: this.arrowDirectionY,
        text: i
      }
    },
    showRowInfo(i) {
      if (this.rowId !== i) {
        this.$emit('updateRowId', i)
      }
    },
    hideRowInfo() {
      if (this.rowId !== 0) {
        this.$emit('updateRowId', 0)
      }
    }
  },
  computed: {
    colors: () => colors,
    slopeFromState() {
      if (this.isForTotalPdf) {
        return this.sector
      } else {
        return (!this.isNav && !this.isDetails) || (this.isDetails && this.isPlate)
          ? this.$store.getters.getSlope(this.slopeId)
          : this.$store.getters.getSlope(this.navId)
      }
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    slopeParams() {
      return this.slopeFromState.slopeParams[0]
    },
    rowsCount() {
      return Math.ceil(
        (this.slopeFromState.slopeParams[0].width * 1000) / this.slopeFromState.materialSize.width
      )
    },
    itemPerRow() {
      return (
        Math.ceil(
          this.defaultSvgParams.height /
            ((this.slopeFromState.materialSize.height / 1000) * this.scale)
        ) + 1
      )
    },
    plateHeight() {
      return (this.slopeFromState.materialSize.height / 1000) * this.scale
    },
    plateWidth() {
      return (this.slopeFromState.materialSize.width / 1000) * this.scale
    },
    rowHeight() {
      return this.itemPerRow * this.plateHeight
    },
    rowStartY() {
      return this.changeHeightDown + this.scaledBiasHeight - this.rowHeight
    },
    arrowStrokeColor() {
      return this.isNav ? 'nav' : 'grey'
    },
    figureStrokeColor() {
      return this.isNav || this.isDetails ? 'var(--grey-stroke)' : 'var(--black)'
    },
    strokeWidth() {
      return this.isNav || this.isDetails ? 2 : 3
    },

    arrowDirectionStrokeWidth() {
      return this.isNav || this.isDetails ? 2 : 4
    },
    figureSvgScale() {
      return this.isNav ? this.defaultSvgParams.navScale : 1
    },
    arrowDirectionSize() {
      return this.isNav ? 5 : 10
    },
    arrowHeight() {
      const { changeHeightUp, changeHeightDown, scaledBiasHeight, changeWidthRight } = this
      const x = changeWidthRight + 30
      const y1 = changeHeightUp + scaledBiasHeight
      const y2 = changeHeightDown + scaledBiasHeight
      return drawSizeArrowVertical(x, y1, y2)
    },
    arrowWidth() {
      const { changeHeightDown, scaledBiasHeight, changeWidthLeft, changeWidthRight } = this
      const y = changeHeightDown + scaledBiasHeight + 30
      return drawSizeArrowHorizontal(changeWidthLeft, y, changeWidthRight)
    },
    arrowBiasHeight() {
      const { changeHeightUp, scaledBiasHeight, changeWidthLeft } = this
      const x = changeWidthLeft - 30
      const y2 = changeHeightUp + scaledBiasHeight
      return drawSizeArrowVertical(x, changeHeightUp, y2)
    },
    arrowBiasWidth() {
      const { changeHeightUp, scaledBiasWidth, changeWidthLeft } = this
      const y = changeHeightUp - 30
      const x2 = changeWidthLeft + scaledBiasWidth
      return drawSizeArrowHorizontal(changeWidthLeft, y, x2)
    },
    arrowDirection() {
      const { arrowDirectionStartX, arrowDirectionEndX, arrowDirectionY, arrowDirectionSize } = this
      return drawArrowDirection(
        arrowDirectionStartX,
        arrowDirectionEndX,
        arrowDirectionY,
        arrowDirectionSize
      )
    },
    cornerLeftBottom() {
      const {
        changeWidthLeft,
        changeHeightUp,
        scaledBiasWidth,
        scaledBiasHeight,
        changeWidthRight,
        changeHeightDown
      } = this
      return `M${changeWidthLeft}, ${changeHeightUp}
      L${changeWidthLeft + scaledBiasWidth}, ${changeHeightUp}
      M${changeWidthLeft + scaledBiasWidth}, ${changeHeightUp}
      L${changeWidthLeft + scaledBiasWidth},
      ${changeHeightUp + scaledBiasHeight}
      M${changeWidthLeft + scaledBiasWidth},
      ${changeHeightUp + scaledBiasHeight}
      L${changeWidthRight}, ${changeHeightUp + scaledBiasHeight}
      M${changeWidthRight}, ${changeHeightUp + scaledBiasHeight}
      L${changeWidthRight}, ${changeHeightDown + scaledBiasHeight}
      M${changeWidthRight}, ${changeHeightDown + scaledBiasHeight}
      L${changeWidthLeft}, ${changeHeightDown + scaledBiasHeight}
      M${changeWidthLeft}, ${changeHeightDown + scaledBiasHeight}
      L${changeWidthLeft}, ${changeHeightUp + scaledBiasHeight}
      M${changeWidthLeft}, ${changeHeightUp + scaledBiasHeight}
      L${changeWidthLeft}, ${changeHeightUp}
      M${changeWidthLeft}, ${changeHeightUp}`
    },
    cornerLeftBottomMask() {
      const {
        changeWidthLeft,
        changeHeightUp,
        scaledBiasWidth,
        scaledBiasHeight,
        changeWidthRight,
        changeHeightDown
      } = this
      return `M${changeWidthLeft}, ${changeHeightUp}
      L${changeWidthLeft + scaledBiasWidth}, ${changeHeightUp}
      L${changeWidthLeft + scaledBiasWidth},
      ${changeHeightUp + scaledBiasHeight}
      L${changeWidthRight}, ${changeHeightUp + scaledBiasHeight}
      L${changeWidthRight}, ${changeHeightDown + scaledBiasHeight}
      L${changeWidthLeft}, ${changeHeightDown + scaledBiasHeight}
      L${changeWidthLeft}, ${changeHeightUp + scaledBiasHeight}
      L${changeWidthLeft}, ${changeHeightUp} Z`
    },
    scaleH() {
      // коэффициент высоты
      return (
        (this.defaultSvgParams.baseHeight * this.figureSvgScale * 100) /
        (this.slopeParams.height + this.slopeParams.biasHeight)
      )
    },
    scaleW() {
      // коэффициент ширины
      return (this.defaultSvgParams.baseWidth * this.figureSvgScale * 100) / this.slopeParams.width
    },
    scale() {
      // применяемый коэффициент в зависимости от того какой из коэффициентов больше
      return getMin(this.scaleW, this.scaleH)
    },
    changeWidthLeft() {
      // изменяем ширину влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeWidthRight() {
      // изменяем ширину вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeHeightUp() {
      // изменяем высоту вверх
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 -
        (this.slopeParams.height * this.scale) / 2 -
        this.scaledBiasHeight / 2
      )
    },
    changeHeightDown() {
      // изменяем высоту вниз
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 +
        (this.slopeParams.height * this.scale) / 2 -
        this.scaledBiasHeight / 2
      )
    },
    scaledBiasHeight() {
      // размер вертикального смещения с применённым коэффициентом
      return this.slopeParams.biasHeight * this.scale
    },
    scaledBiasWidth() {
      // размер вертикального смещения с применённым коэффициентом
      return this.slopeParams.biasWidth * this.scale
    },
    arrowDirectionStartX() {
      // изменяем ширину направляющей стрелки влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        ((this.slopeParams.width / 2) * this.scale) / 2
      )
    },
    arrowDirectionEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        ((this.slopeParams.width / 2) * this.scale) / 2
      )
    },
    arrowDirectionY() {
      // изменяем положение напрвляющей стрелки по высоте
      return (this.changeHeightDown + this.changeHeightUp) / 2 + this.scaledBiasHeight
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass"></style>
